/**
 * LAYOUT
 * Name: Atmosphere
 ********************************* */

.l-atmosphere {
  position: relative;

  @include mq($from: medium, $until: large) {
    @include rem(margin-right, 40px);
    @include rem(margin-left, 40px);
  }

  @include mq($from: large) {
    @include rem(margin-left, 60px);
  }

  @include mq($from: large, $until: xlarge) {
    @include rem(margin-right, 60px);
  }

  @include mq($from: xlarge) {
    @include rem(margin-right, 120px);
  }

  &:not(:first-child) {

    @include mq($until: medium) {
      @include rem(margin-top, 40px);
    }

    @include mq($from: medium, $until: xlarge) {
      @include rem(margin-top, 45px);
    }

    @include mq($from: xlarge) {
      @include rem(margin-top, 90px);
    }
  }
}


  /**
   * CONTAINER
   *********************** */
  .l-atmosphere__container {
    position: relative;
  }
