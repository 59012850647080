/**
 * COMPONENT
 * Name: Slider
 ********************************* */

.c-slider {
  position: relative;

  .slick-arrow {

    @include mq($until: medium) {
      display: none !important;
    }

    @include mq($from: medium) {
      @include appearance;
      position: absolute;
      top: 50%;
      z-index: 1;
      @include rem(width, 60px);
      @include rem(height, 60px);
      @include rem(margin-top, -57px);
      @include font-size(20px, 20px);
      font-weight: $font-bold;
      text-align: center;
      text-indent: -99999px;
      color: $color-foreground;
      background-color: $color-background;
      @include rem(border-radius, 100px);
      @include rem(box-shadow, 0 10px 20px rgba($color-foreground, 0.2));
      transition: box-shadow 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
      overflow: hidden;
      cursor: pointer;
      outline: none;

      &:before {
        position: absolute;
        @include rem(top, 18px);
        right: 0;
        bottom: 0;
        left: 0;
        text-indent: 0;
      }

      &:hover {
        @include rem(box-shadow, 0 15px 25px rgba($color-foreground, 0.1));
        transform: translate3d(0, -2px, 0);
      }
    }
  }

  .slick-prev {
    @include rem(left, -30px);

    &:before {
      content: '←';
    }
  }

  .slick-next {
    @include rem(right, -30px);

    &:before {
      content: '→';
    }
  }
}

  .c-slider__items {}

  .c-slider__dots {

    @include mq($until: medium) {
      display: none !important;
    }

    @include mq($from: medium) {
      display: flex !important;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      @include rem(margin-top, 30px);

      li {
        @include rem(margin-right, 10px);
        @include rem(margin-left, 10px);

        button {
          @include appearance;
          @include rem(width, 30px);
          @include rem(height, 8px);
          text-indent: -999999px;
          background-color: #eee;
          border: 0 none;
          transition: background-color 0.3s ease;
          outline-color: #ddd;
          cursor: pointer;

          &:hover {
            background-color: #ccc;
          }
        }

        &.slick-active {

          button {
            background-color: $color-primary;

            &:hover {
              background-color: $color-primary;
            }
          }
        }
      }
    }
  }
