/**
 * COMPONENT
 * Name: Introduction
 ********************************* */

.c-introduction {
  position: relative;
  height: 100vh;
  @include rem(min-height, 480px);
  overflow: hidden;
  cursor: none;
}


  /**
   * CONTAINER
   *********************** */
  .c-introduction__container {
    height: 100vh;
    @include rem(min-height, 480px);
    background-color: $color-foreground;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-background;
      transform: scaleY(0);
      transform-origin: top center;
    }

    body.is-loaded & {

      &:before {
        animation: dropdown 1s ease 3s forwards;
      }
    }
  }


    /**
     * CONTENT
     *********************** */
    .c-introduction__content {
      position: absolute;
      width: 100%;
      @include rem(max-width, 940px);
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      @include mq($until: small) {
        @include rem(padding-top, 20px);
        @include rem(padding-right, 20px);
        @include rem(padding-left, 20px);
      }

      @include mq($from: small) {
        @include rem(padding-right, 40px);
        @include rem(padding-left, 40px);
      }
    }

    .c-introduction__content--frontface {}

    .c-introduction__content--backface {
      z-index: 999;

      body.is-loaded & {
        animation: hideBackface 1s ease 3s forwards;
      }
    }


      /**
       * TITLE
       *********************** */
      .c-introduction__title {
        position: relative;
        font-weight: $font-bold;

        .c-introduction__content--frontface & {
          color: $color-foreground;
          mix-blend-mode: multiply;
        }

        .c-introduction__content--backface & {
          color: $color-background;

          body.is-loaded & {
            animation: slideUp 1s ease forwards;
          }
        }

        @include mq($until: small) {
          @include font-size(32px, 36px);
        }

        @include mq($from: small, $until: medium) {
          @include font-size(36px, 40px);
        }

        @include mq($from: medium, $until: large) {
          @include font-size(48px, 60px);
        }

        @include mq($from: large, $until: xlarge) {
          @include font-size(56px, 68px);
        }

        @include mq($from: xlarge) {
          @include font-size(60px, 72px);
        }
      }


    /**
     * FOOTER
     *********************** */
    .c-introduction__footer {
      position: absolute;
      right: 0;
      left: 0;
      text-align: center;

      @include mq($until: small) {
        @include rem(bottom, 10px);
      }

      @include mq($from: small, $until: medium) {
        @include rem(bottom, 20px);
      }

      @include mq($from: medium) {
        @include rem(bottom, 45px);
      }
    }


    /**
     * BACKGROUND
     *********************** */
    .c-introduction__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color-primary;
      mix-blend-mode: screen;
      pointer-events: none;
      z-index: 9;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-primary;
        mix-blend-mode: hard-light;
        z-index: 99;
      }
    }

      .c-introduction__picture {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;

        @for $p from 1 through 8 {

          &:nth-child(#{$p}) {
            animation: blinkit#{$p} 1.2s linear infinite;
          }
        }
      }


  /**
   * POINTER
   *********************** */
  .c-introduction__pointer {
    position: absolute;
    display: none;
    @include rem(width, 20px);
    @include rem(height, 20px);
    @include rem(border-radius, 10px);
    background-color: $color-foreground;
    transform: translate3d(-50%, -50%, 0);

    .c-introduction:hover & {
      display: block;
    }
  }
