/**
 * LAYOUT
 * Name: Contact
 ********************************* */

.l-contact {
  position: relative;

  &:not(:first-child) {

    @include mq($from: medium, $until: xlarge) {
      @include rem(margin-top, 60px);
    }

    @include mq($from: xlarge) {
      @include rem(margin-top, 120px);
    }
  }
}

  .l-contact__container {
    color: $color-background;
    background-color: $color-primary;

    @include mq($until: medium) {
      @include rem(padding, 40px 0);
    }

    @include mq($from: medium, $until: xlarge) {
      @include rem(padding, 60px 0);
    }

    @include mq($from: xlarge) {
      @include rem(padding, 120px 0);
    }
  }


    /**
     * HEAD
     *********************** */
    .l-contact__head {
      text-align: center;
    }

      .l-contact__title {}

      .l-contact__description {

        @include mq($until: small) {
          @include font-size(14px, 20px);

          &:not(:first-child) {
            @include rem(margin-top, 15px);
          }
        }

        @include mq($from: small) {

          &:not(:first-child) {
            @include rem(margin-top, 30px);
          }
        }

        a {
          text-decoration: underline;
          color: $color-background;

          &:hover {
            text-decoration: none;
          }
        }
      }


    /**
     * CONTENT
     *********************** */
    .l-contact__content {

      &:not(:first-child) {

        @include mq($until: large) {
          @include rem(margin-top, 40px);
        }

        @include mq($from: large) {
          @include rem(margin-top, 60px);
        }
      }
    }


      /**
       * FRAME
       *********************** */
      .l-contact__frame {

        .c-locator,
        .c-locator__map {
          width: 100%;
          height: 100%;
        }

        @include mq($until: small) {
          @include rem(height, 200px);
          overflow: hidden;
        }

        @include mq($from: small, $until: medium) {
          @include rem(height, 320px);
        }

        @include mq($from: medium, $until: large) {
          @include rem(height, 450px);
        }

        @include mq($from: large) {
          @include rem(height, 550px);
        }
      }


      /**
       * BASELINE
       *********************** */
      .l-contact__line {
        text-align: center;

        &:not(:first-child) {
          @include rem(margin-top, 60px);
        }
      }

        .l-contact__highlight {
          font-family: $font-secondary;
          @include rem(letter-spacing, 1px);

          @include mq($until: small) {
            @include font-size(20px, 28px);
          }

          @include mq($from: small, $until: medium) {
            @include font-size(24px, 32px);
          }

          @include mq($from: medium, $until: large) {
            @include font-size(32px, 40px);
          }

          @include mq($from: large) {
            @include font-size(40px, 52px);
          }
        }

          .l-contact__link {
            position: relative;
            display: inline-block;
            color: $color-background;
            text-decoration: none;
            transform: translate3d(0, 0, 0);
            transition: color 0.4s ease;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: $color-background;
              transform: scaleX(0);
              transform-origin: right;
              transition: transform 0.4s ease;
              z-index: -1;
            }

            @include mq($until: medium) {
              text-decoration: none;
              color: $color-primary;

              &:hover {
                text-decoration: none;
              }

              &:after {
                transform: scaleX(1);
                transform-origin: left;
              }
            }

            @include mq($from: medium) {

              &:hover {
                text-decoration: none;
                color: $color-primary;

                &:after {
                  transform: scaleX(1);
                  transform-origin: left;
                }
              }
            }
          }

        .l-contact__legend {

          @include mq($until: small) {
            @include font-size(14px, 20px);
          }

          &:not(:first-child) {
            @include rem(margin-top, 15px);
          }
        }
