/**
 * LAYOUT
 * Name: Contact
 ********************************* */

.l-overlay {
  position: fixed;
  width: 100vw;
  @include rem(max-width, 720px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.6s ease;
  overflow: hidden;
  z-index: 5;

  body.is-overlayed & {
    transform: translate3d(0, 0, 0);
  }
}


  /**
   * CONTAINER
   *********************** */
  .l-overlay__container {
    height: 100%;
    color: $color-background;
    background-color: $color-foreground;
    overflow: auto;

    @include mq($until: small) {
      @include rem(padding, 40px);
    }

    @include mq($from: small, $until: medium) {
      @include rem(padding, 60px);
    }

    @include mq($from: medium) {
      @include rem(padding, 90px 120px);
    }
  }


    /**
     * HEAD
     *********************** */
    .l-overlay__head {

    }

      .l-overlay__link {
        display: inline-block;
        @include rem(padding-top, 15px);
        @include rem(padding-bottom, 15px);
        @include font-size(11px, 15px);
        font-weight: $font-bold;
        color: $color-background;
        text-transform: uppercase;
        text-decoration: none;
        @include rem(letter-spacing, 1px);

        &:hover {
          text-decoration: underline;
        }
      }


    /**
     * CONTENT
     *********************** */
    .l-overlay__content {
      opacity: 0;
      transition: opacity 0.6s ease;

      body.is-overlayed & {
        opacity: 1;
      }
    }

      .l-overlay__item {
        display: none;
      }
