/**
 * COMPONENT
 * Name: Reference
 ********************************* */

.c-reference {
  position: relative;
  backface-visibility: hidden;
}


  /**
   * LINK
   *********************** */
  .c-reference__link {
    color: $color-foreground;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }

  a.c-reference__link {
    cursor: pointer;
  }


    /**
     * CONTAINER
     *********************** */
    .c-reference__container {}


      /**
       * HEAD
       *********************** */
      .c-reference__background {
        position: relative;
        background-color: $color-foreground;

        @include mq($from: medium) {

          &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-primary;
            mix-blend-mode: hard-light;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.4s ease;
            z-index: 1;

            .c-reference__link:hover & {
              transform: scaleX(1);
            }
          }
        }

        @include mq($from: medium, $until: large) {
          @include rem(height, 150px);
        }

        @include mq($from: large) {
          @include rem(height, 180px);
        }
      }

        .c-reference__picture {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: grayscale(1);
          opacity: 0.4;
          transition: opacity 0.4s ease;

          @include mq($from: medium) {

            .c-reference__link:hover & {
              opacity: 0.8;
            }
          }
        }

        .c-reference__logo {
          position: absolute;
          max-width: 75%;
          top: 50%;
          left: 50%;
          color: $color-background;
          transform: translate3d(-50%, -50%, 0);
          transition: transform 0.4s ease, opacity 0.4s ease;
          z-index: 2;

          @include mq($from: medium) {

            .c-reference__link:hover & {
              transform: translate3d(-50%, calc(-50% - 10px), 0);
              opacity: 0.8;
            }
          }
        }

        .c-reference__label {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          @include font-size(11px, 15px);
          font-weight: $font-bold;
          text-align: center;
          text-transform: uppercase;
          @include rem(letter-spacing, 1px);
          color: $color-background;
          opacity: 0;
          transition: opacity 0.4s ease, transform 0.4s ease;
          z-index: 2;

          @include mq($from: medium) {

            .c-reference__link:hover & {
              transform: translate3d(0, -30px, 0);
              opacity: 1;
            }
          }
        }


      /**
       * CONTENT
       *********************** */
      .c-reference__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include rem(padding, 20px);
        text-align: center;
      }

        .c-reference__heading {
          position: relative;
        }

          .c-reference__title,
          .c-reference__backface {
            @include font-size(24px, 30px);
            font-weight: $font-bold;
            text-transform: uppercase;
            color: $color-background;
          }

          .c-reference__title {
            mix-blend-mode: difference;
          }

          .c-reference__backface {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            z-index: 9;
            transition: opacity 0.4s ease;

            .c-reference__link:hover & {
              opacity: 1;
            }
          }

        .c-reference__description {
          color: $color-background;

          &:not(:first-child) {
            @include rem(margin-top, 10px);
          }

          @include mq($until: small) {
            @include font-size(11px, 15px);
          }

          @include mq($from: small) {
            @include font-size(14px, 20px);
          }

          @include mq($from: medium) {
            transition: transform 0.4s ease, opacity 0.4s ease;

            .c-reference__link:hover & {
              transform: translate3d(0, -15px, 0);
              opacity: 0;
            }
          }
        }

          .c-reference__note {
            @include font-size(11px, 15px);
          }
